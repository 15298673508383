import * as React from 'react';

function SvgSpringPlus8(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.925 22.743V25.367L5.081 28.611l20.104 4.116L5.08 36.844l20.102 4.116L5.08 45.075l20.102 4.115L5.08 53.307l20.104 4.116L5.08 61.539l20.102 4.117L5.08 69.77l20.102 4.116-4.258.871v4.998h5V77.13l15.844-3.244-20.104-4.116 20.104-4.117-20.104-4.116 20.104-4.115-20.104-4.115 20.104-4.116-20.104-4.117L41.77 40.96l-20.104-4.117 20.104-4.115-20.104-4.115 4.26-.872v-4.997h-5z"
        fill={props.springcolor}
      />
      <path
        d="M23.424 126.421v6.657l-10.051 6.25 20.103 12.5-20.103 12.5 20.103 12.5-20.103 12.5 20.103 12.5-20.103 12.5 20.103 12.5-20.103 12.5 20.103 12.5-20.103 12.5 20.103 12.501-10.052 6.25v6.657"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.894 101.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.394 79a22.5 22.5 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M32.327 84.153a.9.9 0 00-.19 1.653 17.945 17.945 0 012.938 2.04 17.795 17.795 0 013.426 3.932 18.037 18.037 0 012.764 11.587 17.848 17.848 0 01-.727 3.502 18.156 18.156 0 01-3.491 6.314v.001a17.982 17.982 0 01-2.52 2.421 17.941 17.941 0 01-2.908 1.868 18.118 18.118 0 01-4.879 1.681 17.915 17.915 0 01-6.966-.052 17.994 17.994 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.207.657.893.893 0 00.319.611c.09.076.195.133.308.169a19.787 19.787 0 003.854.8 19.884 19.884 0 003.847.023 19.552 19.552 0 003.735-.711 19.922 19.922 0 005.164-2.352 19.933 19.933 0 003-2.365 19.685 19.685 0 004.463-6.456 19.963 19.963 0 001.203-3.817 19.622 19.622 0 00.058-7.669 19.826 19.826 0 00-6.568-11.338 19.638 19.638 0 00-3.233-2.246.9.9 0 00-.684-.079zM23.515 81.731a19.704 19.704 0 00-5.651.794 19.887 19.887 0 00-6.716 3.458 19.668 19.668 0 00-5.912 7.715 19.985 19.985 0 00-1.203 3.817 19.625 19.625 0 00-.058 7.669 19.83 19.83 0 006.568 11.338 19.477 19.477 0 003.234 2.246.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.941-9.038 18.122 18.122 0 01-1.208-5.026 17.886 17.886 0 011.388-8.722 18.2 18.2 0 011.765-3.184 17.822 17.822 0 015.018-4.833 18.01 18.01 0 014.696-2.14 18.034 18.034 0 015.135-.72 17.817 17.817 0 015.256.823.9.9 0 00.54-1.718 19.734 19.734 0 00-5.785-.906z"
        fill="#FCFAF2"
      />
      <path
        d="M30.449 84.097l-5.619 1.462 1.539-5.586 4.08 4.124z"
        fill="#F9AEA5"
      />
      <path
        d="M26.409 79.546a.43.43 0 00-.455.313l-1.538 5.587a.428.428 0 00.523.528l5.618-1.463a.43.43 0 00.197-.716l-4.08-4.123a.43.43 0 00-.265-.126zm.177 1.256l3.034 3.067-4.179 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.34 118.904l5.618-1.463-1.539 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M22.027 117.018a.447.447 0 00-.178.008l-5.618 1.463a.43.43 0 00-.198.716l4.08 4.123a.426.426 0 00.415.114.428.428 0 00.305-.301l1.539-5.586a.43.43 0 00-.345-.537zm-.68 1.025l-1.145 4.155-3.035-3.067 4.18-1.088zM23.394 115.196h2.683v-6.443h5.594v-2.473h-5.626v-2.473h5.626v-2.474h-4.155l6.563-11.575h-5.884l-4.801 9.684v15.754zm0 0H20.71v-6.443h-5.593v-2.473h5.626v-2.473h-5.626v-2.474h4.154l-6.563-11.575h5.885l4.8 9.684"
        fill="#FCFAF2"
      />
      <path
        d="M34.957 0h-23v23h23V0zM34.957 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringPlus8;
