import * as React from 'react';

function SvgSpringPlus4(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.99 25.023v3.25l-10.052 3.052L33.04 37.43l-20.103 6.104 20.103 6.104-20.103 6.104 20.103 6.104-20.103 6.104 20.103 6.103-20.103 6.104L33.04 86.26l-20.103 6.104 20.103 6.104-10.052 3.052v3.251M22.99 154.28v5.522l-10.052 5.183 20.103 10.368-20.103 10.368 20.103 10.368-20.103 10.367 20.103 10.368-20.103 10.368 20.103 10.367-20.103 10.368 20.103 10.368-20.103 10.368L33.04 279.03l-10.052 5.184v5.522"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.459 129.5a22.502 22.502 0 01-22.5 22.5 22.502 22.502 0 01-22.5-22.5 22.502 22.502 0 0138.41-15.91 22.502 22.502 0 016.59 15.91z"
        fill="#5BAD92"
      />
      <path
        d="M31.892 112.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.646 17.646 0 012.938 2.04 17.86 17.86 0 014.288 5.428 18.14 18.14 0 011.681 4.879 17.964 17.964 0 01.22 5.212 17.848 17.848 0 01-.726 3.502 18.183 18.183 0 01-2.405 4.908c-.341.49-.703.959-1.086 1.406v.001a17.983 17.983 0 01-2.52 2.421 17.904 17.904 0 01-2.909 1.868 18.116 18.116 0 01-4.878 1.681 17.915 17.915 0 01-6.966-.052 17.989 17.989 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.207.657.895.895 0 00.319.611c.09.076.195.133.308.169a19.787 19.787 0 003.854.8 19.886 19.886 0 003.847.023 19.547 19.547 0 003.735-.711 19.922 19.922 0 005.164-2.352 19.954 19.954 0 003-2.365 19.707 19.707 0 004.463-6.456 19.992 19.992 0 001.203-3.817 19.684 19.684 0 00-.387-9.515 19.783 19.783 0 00-1.403-3.519 19.95 19.95 0 00-2.055-3.198 19.558 19.558 0 00-2.665-2.775 19.493 19.493 0 00-3.233-2.246.897.897 0 00-.684-.078zM23.08 109.731a20.027 20.027 0 00-3.804.349 20.02 20.02 0 00-3.64 1.063 19.908 19.908 0 00-3.37 1.734 19.696 19.696 0 00-7.464 8.821 19.97 19.97 0 00-1.204 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.329 5.527 19.95 19.95 0 001.734 3.372 19.787 19.787 0 002.365 3 19.585 19.585 0 002.955 2.523c.539.375 1.101.726 1.685 1.05a.902.902 0 001.223-.35.899.899 0 00-.35-1.223 17.916 17.916 0 01-5.358-4.561 18.025 18.025 0 01-3.144-6.108 17.979 17.979 0 01-.625-6.89 17.801 17.801 0 01.727-3.502 18.184 18.184 0 012.405-4.909 17.686 17.686 0 012.292-2.684 17.94 17.94 0 015.791-3.726 17.99 17.99 0 018.52-1.187 17.697 17.697 0 013.502.727.905.905 0 001.129-.589.898.898 0 00-.589-1.129 19.787 19.787 0 00-3.854-.799 19.577 19.577 0 00-1.931-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M30.014 112.096l-5.619 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M25.974 107.546a.432.432 0 00-.454.313l-1.54 5.587a.425.425 0 00.11.414.426.426 0 00.414.114l5.618-1.463a.43.43 0 00.197-.716l-4.08-4.123a.432.432 0 00-.265-.126zm.177 1.256l3.034 3.067-4.179 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.904 146.904l5.619-1.463-1.539 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.592 145.018a.447.447 0 00-.178.008l-5.619 1.463a.429.429 0 00-.197.716l4.08 4.123a.426.426 0 00.415.114.43.43 0 00.305-.301l1.539-5.586a.428.428 0 00-.345-.537zm-.68 1.025l-1.145 4.155-3.035-3.067 4.18-1.088zM22.959 143.196h2.683v-6.443h5.594v-2.473H25.61v-2.473h5.626v-2.474H27.08l6.563-11.574H27.76l-4.801 9.683v15.754zm0 0h-2.684v-6.443h-5.593v-2.473h5.626v-2.473h-5.626v-2.474h4.155l-6.564-11.574h5.884l4.802 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.522 0h-23v23h23V0zM34.522 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringPlus4;
