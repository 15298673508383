import * as React from 'react';

function SvgCjpyLogo(props) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 13.229 13.229"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <path
          id="CJPY_logo_svg__a"
          d="M1117.229 692.965h627.911v57.039h-627.911z"
        />
      </defs>
      <g strokeWidth={30}>
        <path
          style={{
            InkscapeStroke: 'none',
          }}
          d="M13.23 6.615a6.615 6.615 0 01-6.615 6.614A6.615 6.615 0 010 6.615 6.615 6.615 0 016.615 0a6.615 6.615 0 016.614 6.615z"
          color="#000"
          fill="#5bad92"
        />
        <path
          style={{
            InkscapeStroke: 'none',
          }}
          d="M9.24 1.515a.265.265 0 00-.158.126.265.265 0 00.103.36 5.271 5.271 0 011.87 1.756 5.302 5.302 0 01.733 3.922 5.273 5.273 0 01-.56 1.506 5.272 5.272 0 01-.6.864 5.239 5.239 0 01-1.596 1.26 5.314 5.314 0 01-2.966.56 5.24 5.24 0 01-1.029-.214.265.265 0 00-.332.173.265.265 0 00.173.332 5.8 5.8 0 002.82.164 5.83 5.83 0 003.684-2.387 5.801 5.801 0 00.777-1.586 5.802 5.802 0 00.236-1.133 5.773 5.773 0 00-.384-2.756 5.842 5.842 0 00-1.619-2.264 5.77 5.77 0 00-.95-.66.265.265 0 00-.201-.023zM6.65.803a5.785 5.785 0 00-2.188.415 5.843 5.843 0 00-2.263 1.619 5.77 5.77 0 00-.922 1.484 5.876 5.876 0 00-.442 1.69 5.771 5.771 0 00.202 2.23 5.843 5.843 0 001.8 2.79 5.77 5.77 0 00.95.66.265.265 0 00.36-.103.265.265 0 00-.103-.36 5.271 5.271 0 01-1.87-1.755A5.301 5.301 0 011.44 5.55a5.272 5.272 0 01.56-1.506 5.272 5.272 0 016.191-2.47.265.265 0 00.332-.173.265.265 0 00-.173-.332 5.8 5.8 0 00-1.7-.266z"
          color="#000"
          fill="#fcfaf2"
        />
        <g color="#000">
          <path
            style={{
              InkscapeStroke: 'none',
            }}
            d="M8.689 1.498l-1.652.43.452-1.642z"
            fill="#f9aea5"
          />
          <path
            style={{
              InkscapeStroke: 'none',
            }}
            d="M7.5.16a.126.126 3.26 00-.133.093l-.452 1.642a.126.126 3.26 00.154.155l1.651-.43a.126.126 3.26 00.058-.21L7.578.198A.126.126 3.26 007.502.16zm.053.37l.892.901-1.228.32z"
            fill="#fcfaf2"
          />
        </g>
        <g color="#000">
          <path
            style={{
              InkscapeStroke: 'none',
            }}
            d="M4.54 11.731l1.652-.43-.452 1.642z"
            fill="#f9aea5"
          />
          <path
            style={{
              InkscapeStroke: 'none',
            }}
            d="M6.213 11.177a.126.126 3.26 00-.053.002l-1.651.43a.126.126 3.26 00-.058.21l1.2 1.213a.126.126 3.26 00.21-.055l.453-1.643a.126.126 3.26 00-.101-.157zm-.2.301l-.337 1.221-.892-.901z"
            fill="#fcfaf2"
          />
        </g>
        <path
          style={{
            InkscapeFontSpecification: "'Gadugi, Bold'",
            InkscapeStroke: 'none',
          }}
          d="M7.403 10.641V8.747h1.645V8.02H7.394v-.727h1.654v-.727H7.826l1.93-3.403h-1.73L6.615 6.01 5.203 3.163h-1.73l1.93 3.403H4.18v.727h1.654v.727H4.181v.727h1.645v1.894z"
          color="#000"
          aria-label="\uFFE5"
          fontSize={273.862}
          letterSpacing={0}
          wordSpacing={0}
          fill="#fcfaf2"
          strokeWidth={7.445}
        />
      </g>
    </svg>
  );
}

export default SvgCjpyLogo;
