import * as React from 'react';

function SvgSpringMinus4(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.12 25.03v5.535L13.067 35.76 33.17 46.153 13.068 56.546 33.17 66.938 13.068 77.33 33.17 87.723 13.068 98.115l20.103 10.392L13.068 118.9l20.103 10.392-20.103 10.392 20.103 10.393-10.052 5.196v5.535M23.12 210.398v3.234l-10.052 3.036 20.103 6.073-20.103 6.072 20.103 6.073-20.103 6.072 20.103 6.073-20.103 6.072 20.103 6.073-20.103 6.072 20.103 6.073-20.103 6.072 20.103 6.073-10.052 3.036v3.234"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.589 185.5a22.502 22.502 0 01-22.5 22.5 22.502 22.502 0 01-22.5-22.5 22.502 22.502 0 0138.41-15.91 22.502 22.502 0 016.59 15.91z"
        fill="#5BAD92"
      />
      <path
        d="M32.022 168.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.66 17.66 0 012.938 2.04 17.838 17.838 0 013.426 3.932 18.08 18.08 0 012.785 8.091 17.893 17.893 0 01-.294 5.251 17.965 17.965 0 01-1.094 3.472 18.277 18.277 0 01-1.765 3.183c-.34.49-.703.959-1.086 1.406v.001a17.983 17.983 0 01-2.52 2.421 17.904 17.904 0 01-2.908 1.868 18.116 18.116 0 01-4.879 1.681 17.917 17.917 0 01-6.966-.052 17.989 17.989 0 01-1.746-.454.904.904 0 00-.96.28.91.91 0 00-.207.657.895.895 0 00.319.611c.09.076.195.133.308.169a19.787 19.787 0 003.854.8 19.886 19.886 0 003.847.023 19.547 19.547 0 003.735-.711 19.908 19.908 0 005.164-2.352 19.954 19.954 0 003-2.365 19.684 19.684 0 004.463-6.456 19.992 19.992 0 001.204-3.817 19.684 19.684 0 00-.387-9.515 19.783 19.783 0 00-1.404-3.519 19.95 19.95 0 00-2.054-3.198 19.58 19.58 0 00-4.214-3.971 19.674 19.674 0 00-1.685-1.05.897.897 0 00-.684-.078zM23.21 165.731a20.026 20.026 0 00-3.804.349 20.013 20.013 0 00-3.64 1.063 19.908 19.908 0 00-3.37 1.734 19.696 19.696 0 00-7.464 8.821 19.967 19.967 0 00-1.204 3.817 19.612 19.612 0 00-.057 7.669 19.83 19.83 0 006.568 11.338 19.457 19.457 0 003.233 2.246.902.902 0 001.223-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.94-9.038 18.122 18.122 0 01-1.208-5.026 17.886 17.886 0 011.388-8.723 18.071 18.071 0 012.85-4.589 17.77 17.77 0 013.933-3.427 17.904 17.904 0 013.065-1.577 17.99 17.99 0 018.52-1.187 17.697 17.697 0 013.502.727.906.906 0 001.128-.589.898.898 0 00-.588-1.129 19.787 19.787 0 00-3.854-.799 19.577 19.577 0 00-1.931-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M30.144 168.096l-5.619 1.463 1.539-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M26.104 163.546a.432.432 0 00-.454.313l-1.539 5.586a.429.429 0 00.523.529l5.618-1.463a.43.43 0 00.197-.716l-4.08-4.123a.432.432 0 00-.265-.126zm.177 1.256l3.034 3.067-4.178 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.034 202.904l5.619-1.463-1.539 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.723 201.018a.447.447 0 00-.179.008l-5.618 1.463a.429.429 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.43.43 0 00.306-.301l1.538-5.586a.428.428 0 00-.345-.537zm-.682 1.025l-1.144 4.155-3.034-3.067 4.178-1.088zM23.089 199.196h2.683v-6.443h5.594v-2.473H25.74v-2.473h5.626v-2.474H27.21l6.563-11.574H27.89l-4.801 9.683v15.754zm0 0h-2.684v-6.443h-5.593v-2.473h5.626v-2.473h-5.626v-2.474h4.155l-6.564-11.574h5.885l4.8 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.652 0h-23v23h23V0zM34.652 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringMinus4;
