import * as React from 'react';

function SvgSpringPlus3(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.38 25.364v3.534L13.33 32.215l20.103 6.634-20.103 6.635 20.103 6.634-20.103 6.634 20.103 6.635L13.33 72.02l20.103 6.635L13.33 85.29l20.103 6.634L13.33 98.56l20.103 6.634-10.051 3.317v3.534M23.38 161.34v5.234l-10.051 4.913 20.103 9.828-20.103 9.827 20.103 9.827-20.103 9.828 20.103 9.827-20.103 9.827 20.103 9.828-20.103 9.827 20.103 9.828-20.103 9.827 20.103 9.827-10.051 4.914v5.234"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.85 136.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.35 114a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M32.283 119.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.645 17.645 0 012.938 2.04 17.861 17.861 0 014.289 5.428 18.14 18.14 0 011.68 4.879 17.996 17.996 0 01.221 5.212 17.849 17.849 0 01-.727 3.502 18.156 18.156 0 01-2.405 4.908c-.34.49-.703.959-1.086 1.406v.001a17.96 17.96 0 01-3.932 3.426 17.888 17.888 0 01-3.065 1.578 18.128 18.128 0 01-5.025 1.208 18.011 18.011 0 01-3.496-.022 17.99 17.99 0 01-3.501-.726.905.905 0 00-.96.28.91.91 0 00-.207.657.893.893 0 00.319.611c.09.076.195.133.308.169a19.782 19.782 0 005.785.906 19.543 19.543 0 005.65-.794 19.908 19.908 0 005.164-2.352 19.971 19.971 0 003-2.365 19.708 19.708 0 004.465-6.456 19.964 19.964 0 001.203-3.817 19.653 19.653 0 00.058-7.669 19.804 19.804 0 00-1.848-5.365 19.78 19.78 0 00-4.72-5.973 19.457 19.457 0 00-3.234-2.246.898.898 0 00-.684-.078zM23.47 116.731a20.028 20.028 0 00-3.803.349 20.02 20.02 0 00-3.64 1.063 19.922 19.922 0 00-3.37 1.734 19.707 19.707 0 00-7.464 8.821 19.967 19.967 0 00-1.204 3.817 19.612 19.612 0 00-.057 7.669 19.83 19.83 0 006.568 11.338 19.493 19.493 0 003.233 2.246.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.902 17.902 0 01-7.941-9.038 18.122 18.122 0 01-1.208-5.026 17.886 17.886 0 011.388-8.723 18.071 18.071 0 012.85-4.589 17.791 17.791 0 013.933-3.427 17.904 17.904 0 013.066-1.577 17.988 17.988 0 018.52-1.187 17.701 17.701 0 013.501.727.905.905 0 001.129-.589.9.9 0 00-.589-1.129 19.787 19.787 0 00-3.854-.799 19.575 19.575 0 00-1.931-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M30.405 119.096l-5.619 1.463 1.539-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M26.365 114.546a.434.434 0 00-.454.313l-1.539 5.587a.425.425 0 00.109.414.426.426 0 00.414.114l5.618-1.463a.429.429 0 00.197-.716l-4.08-4.123a.432.432 0 00-.265-.126zm.177 1.256l3.034 3.067-4.178 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.295 153.904l5.619-1.463-1.539 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.984 152.018a.447.447 0 00-.179.008l-5.618 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.429.429 0 00.306-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.682 1.025l-1.144 4.155-3.034-3.067 4.178-1.088zM23.35 150.196h2.683v-6.443h5.594v-2.473H26v-2.473h5.626v-2.474h-4.155l6.564-11.574H28.15l-4.801 9.683v15.754zm0 0h-2.684v-6.443h-5.593v-2.473H20.7v-2.473h-5.626v-2.474h4.155l-6.564-11.574h5.885l4.801 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.914 0h-23v23h23V0zM34.914 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringPlus3;
