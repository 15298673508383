import * as React from 'react';

function SvgSpringMinus2(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.337 25.236V30.2l-10.052 4.66 20.104 9.321-20.104 9.321 20.104 9.321-20.104 9.321 20.104 9.32-20.104 9.322 20.104 9.321-20.104 9.32 20.104 9.321-20.104 9.321 20.104 9.321-10.052 4.66v4.965M23.337 196.07v3.818l-10.052 3.584 20.104 7.17-20.104 7.169 20.104 7.169-20.104 7.169 20.104 7.169-20.104 7.169 20.104 7.17-20.104 7.169 20.104 7.169-20.104 7.169 20.104 7.169-10.052 3.585v3.818"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.806 171.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.307 149a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M32.24 154.154a.896.896 0 00-.619 1.112.9.9 0 00.429.54 17.646 17.646 0 012.937 2.04 17.84 17.84 0 013.427 3.932 18.08 18.08 0 012.543 6.375 17.964 17.964 0 01.22 5.212 17.819 17.819 0 01-.727 3.502 18.156 18.156 0 01-3.49 6.314v.001a17.982 17.982 0 01-2.521 2.421 17.941 17.941 0 01-2.908 1.868 18.118 18.118 0 01-4.878 1.681 17.92 17.92 0 01-6.967-.052 17.994 17.994 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.206.657.892.892 0 00.318.611c.09.076.195.133.308.169a19.79 19.79 0 003.854.8 19.883 19.883 0 003.848.023 19.55 19.55 0 003.734-.711A19.907 19.907 0 0034 188.123a19.933 19.933 0 003-2.365 19.685 19.685 0 004.464-6.456 19.963 19.963 0 001.203-3.817 19.622 19.622 0 00.058-7.669 19.804 19.804 0 00-1.848-5.365 19.979 19.979 0 00-2.055-3.198 19.581 19.581 0 00-4.214-3.971 19.657 19.657 0 00-1.684-1.05.898.898 0 00-.685-.078zM23.427 151.731a20.026 20.026 0 00-3.804.349 20.023 20.023 0 00-3.639 1.063 19.895 19.895 0 00-3.371 1.734 19.7 19.7 0 00-7.464 8.821 19.967 19.967 0 00-1.203 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.328 5.527 19.947 19.947 0 001.734 3.372 19.787 19.787 0 002.366 3 19.579 19.579 0 002.954 2.523c.54.375 1.101.726 1.685 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.94-9.038 18.122 18.122 0 01-1.209-5.026 17.886 17.886 0 011.389-8.723 18.069 18.069 0 012.85-4.589 17.784 17.784 0 013.932-3.427 17.903 17.903 0 013.066-1.577 17.988 17.988 0 018.52-1.187 17.697 17.697 0 013.502.727.905.905 0 001.129-.589.898.898 0 00-.59-1.129 19.79 19.79 0 00-3.854-.799 19.574 19.574 0 00-1.93-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M30.361 154.096l-5.618 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M26.321 149.546a.431.431 0 00-.454.313l-1.538 5.586a.43.43 0 00.522.529l5.619-1.463a.429.429 0 00.197-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.035 3.067-4.18 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.252 188.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.94 187.018a.447.447 0 00-.178.008l-5.619 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.428.428 0 00.306-.301l1.538-5.586a.43.43 0 00-.344-.537zm-.681 1.025l-1.144 4.155-3.035-3.067 4.179-1.088zM23.306 185.196h2.684v-6.443h5.593v-2.473h-5.625v-2.473h5.625v-2.474H27.43l6.563-11.574h-5.884l-4.802 9.683v15.754zm0 0h-2.683v-6.443h-5.594v-2.473h5.626v-2.473H15.03v-2.474h4.155l-6.563-11.574h5.884l4.801 9.683"
        fill="#FCFAF2"
      />
      <path d="M34.87 0h-23v23h23V0zM34.87 292h-23v23h23v-23z" fill="#5BAD92" />
    </svg>
  );
}

export default SvgSpringMinus2;
