import * as React from 'react';

function SvgSpringMinus6(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.902 24.825v6.13L12.85 36.71l20.104 11.509L12.85 59.728l20.104 11.509L12.85 82.746l20.104 11.509-20.104 11.509 20.104 11.509-20.104 11.509 20.104 11.509-20.104 11.51 20.104 11.509-10.052 5.754v6.13"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M20.403 221.631v3.231l-17.278 4.334 20.102 5.042-20.102 5.044 20.104 5.044-20.104 5.043 20.102 5.042-20.102 5.044 20.104 5.043-20.104 5.044 20.102 5.042-20.102 5.043 20.104 5.044-2.826.709v6.856h5v-3.231l17.277-4.334-20.106-5.044 20.106-5.043-20.104-5.042 20.104-5.044-20.106-5.043 20.106-5.044-20.104-5.042 20.104-5.043-20.106-5.044 20.106-5.044-20.104-5.042 2.827-.709v-6.856h-5z"
        fill={props.springcolor}
      />
      <path
        d="M45.371 199.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0122.872 177a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M31.804 182.154a.896.896 0 00-.618 1.112.9.9 0 00.429.54 17.645 17.645 0 012.937 2.04 17.861 17.861 0 014.29 5.428 18.139 18.139 0 011.68 4.879 17.964 17.964 0 01.22 5.212 17.819 17.819 0 01-.726 3.502 18.128 18.128 0 01-3.491 6.314v.001a17.96 17.96 0 01-3.932 3.426 17.903 17.903 0 01-3.066 1.578 18.128 18.128 0 01-5.025 1.208 18.011 18.011 0 01-3.495-.022 17.99 17.99 0 01-3.502-.726.905.905 0 00-.96.28.91.91 0 00-.206.657.893.893 0 00.318.611c.09.076.195.133.308.169a19.79 19.79 0 003.855.8 19.883 19.883 0 003.847.023 19.543 19.543 0 003.734-.711 19.895 19.895 0 005.164-2.352 19.952 19.952 0 003-2.365 19.685 19.685 0 004.464-6.456 19.964 19.964 0 001.203-3.817 19.653 19.653 0 00.058-7.669 19.773 19.773 0 00-1.062-3.639 19.959 19.959 0 00-1.734-3.372 19.78 19.78 0 00-3.772-4.327 19.457 19.457 0 00-3.233-2.246.898.898 0 00-.685-.078zM22.992 179.731a20.028 20.028 0 00-3.804.349 20.02 20.02 0 00-3.639 1.063 19.922 19.922 0 00-3.371 1.734 19.697 19.697 0 00-7.464 8.821 19.967 19.967 0 00-1.203 3.817 19.609 19.609 0 00-.324 5.781 19.75 19.75 0 001.328 5.527 19.947 19.947 0 001.734 3.372 19.787 19.787 0 002.366 3 19.59 19.59 0 002.954 2.523c.54.375 1.102.726 1.685 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.902 17.902 0 01-7.94-9.038 18.122 18.122 0 01-1.209-5.026 17.89 17.89 0 01.748-6.997 18.184 18.184 0 012.406-4.909 17.686 17.686 0 012.292-2.684 17.96 17.96 0 017.421-4.288 17.817 17.817 0 013.395-.647 18.014 18.014 0 013.495.022 17.701 17.701 0 013.502.727.905.905 0 001.129-.589.9.9 0 00-.59-1.129 19.787 19.787 0 00-3.854-.799 19.575 19.575 0 00-1.93-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M29.926 182.096l-5.618 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M25.886 177.546a.434.434 0 00-.454.313l-1.538 5.586a.428.428 0 00.3.527.43.43 0 00.222.002l5.619-1.463a.429.429 0 00.197-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.035 3.067-4.179 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.817 216.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.505 215.018a.447.447 0 00-.178.008l-5.619 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.429.429 0 00.306-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.681 1.025l-1.144 4.155-3.035-3.067 4.179-1.088zM22.872 213.196h2.683v-6.443h5.593v-2.473h-5.625v-2.473h5.625v-2.474h-4.154l6.563-11.574h-5.884l-4.802 9.683v15.754zm0 0h-2.684v-6.443h-5.593v-2.473h5.625v-2.473h-5.625v-2.474h4.154l-6.563-11.574h5.884l4.801 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.435 0h-23v23h23V0zM34.435 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringMinus6;
