import * as React from 'react';

function SvgSpringPlus10(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.142 22.545v.528L2.625 25.928l20.105 3.278-20.105 3.278 20.103 3.276-20.103 3.278 20.103 3.278-20.103 3.277L22.73 48.87 2.625 52.148l20.103 3.277-20.103 3.278 20.103 3.277-2.586.422v2.962h5v-.529l17.516-2.855-20.105-3.277 20.105-3.278-20.104-3.277 20.104-3.278-20.104-3.277 20.104-3.277-20.105-3.278 20.105-3.277-20.104-3.277 20.104-3.278-20.102-3.278 2.586-.421v-2.962h-5z"
        fill={props.springcolor}
      />
      <path
        d="M22.642 112.261v7.235l-10.052 6.792 20.104 13.583-20.104 13.584 20.104 13.584-20.104 13.584 20.104 13.584L12.59 207.79l20.104 13.584-20.104 13.584 20.104 13.584-20.104 13.584 20.104 13.583-10.052 6.792v7.235"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.111 87.5a22.5 22.5 0 11-45 0 22.5 22.5 0 0145 0z"
        fill="#5BAD92"
      />
      <path
        d="M31.544 70.153a.9.9 0 00-.19 1.653 17.945 17.945 0 012.938 2.04 17.795 17.795 0 013.427 3.932 18.037 18.037 0 012.763 11.587 17.844 17.844 0 01-.727 3.502 18.182 18.182 0 01-2.405 4.908 17.86 17.86 0 01-6.514 5.696 18.118 18.118 0 01-4.879 1.681 17.915 17.915 0 01-6.966-.052 17.994 17.994 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.207.657.893.893 0 00.319.611c.09.076.195.133.308.169a19.787 19.787 0 003.854.8 19.884 19.884 0 003.848.023 19.552 19.552 0 003.734-.711 19.922 19.922 0 005.164-2.352 19.933 19.933 0 003-2.365 19.685 19.685 0 004.464-6.456 19.982 19.982 0 001.203-3.817 19.605 19.605 0 00.058-7.669 19.826 19.826 0 00-6.568-11.338 19.655 19.655 0 00-3.234-2.246.9.9 0 00-.684-.079zM22.732 67.731a19.705 19.705 0 00-5.65.794 19.887 19.887 0 00-6.717 3.458 19.663 19.663 0 00-5.911 7.715 19.985 19.985 0 00-1.203 3.817 19.628 19.628 0 00-.058 7.669 19.838 19.838 0 005.162 10.011 19.584 19.584 0 002.954 2.523c.54.375 1.101.726 1.685 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.941-9.038 18.076 18.076 0 01-1.208-5.026 17.883 17.883 0 011.389-8.722 18.2 18.2 0 011.764-3.184 17.822 17.822 0 015.018-4.833 18.01 18.01 0 014.696-2.14 18.034 18.034 0 015.136-.72 17.817 17.817 0 015.256.823.9.9 0 00.54-1.718 19.734 19.734 0 00-5.786-.906z"
        fill="#FCFAF2"
      />
      <path
        d="M29.666 70.097l-5.618 1.462 1.538-5.586 4.08 4.124z"
        fill="#F9AEA5"
      />
      <path
        d="M25.626 65.546a.43.43 0 00-.454.313l-1.539 5.587a.429.429 0 00.523.528l5.619-1.463a.43.43 0 00.197-.716l-4.08-4.123a.43.43 0 00-.266-.126zm.177 1.256l3.035 3.067-4.18 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.557 104.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.245 103.018a.447.447 0 00-.179.008l-5.618 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.428.428 0 00.306-.301l1.538-5.586a.43.43 0 00-.344-.537zm-.681 1.025l-1.144 4.155-3.035-3.067 4.179-1.088zM22.611 101.196h2.684v-6.443h5.593V92.28h-5.626v-2.473h5.626v-2.474h-4.155l6.564-11.575h-5.884l-4.802 9.684v15.754zm0 0h-2.683v-6.443h-5.594V92.28h5.626v-2.473h-5.626v-2.474h4.155l-6.563-11.575h5.884l4.801 9.684"
        fill="#FCFAF2"
      />
      <path
        d="M34.175 0h-23v23h23V0zM34.175 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringPlus10;
