import * as React from 'react';

function SvgSpring0(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.555 25.444v4.377l-10.052 4.108 20.103 8.218-20.103 8.217 20.103 8.217-20.103 8.217 20.103 8.218-20.103 8.217 20.103 8.217-20.103 8.217 20.103 8.218-20.103 8.217 20.103 8.217-10.052 4.109v4.376M22.555 182.115v4.387l-10.052 4.119 20.103 8.237-20.103 8.237 20.103 8.238-20.103 8.237 20.103 8.237-20.103 8.237 20.103 8.237-20.103 8.238 20.103 8.237-20.103 8.237 20.103 8.237-10.052 4.119v4.387"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.024 157.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0122.524 135a22.498 22.498 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M31.457 140.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.662 17.662 0 012.938 2.04 17.84 17.84 0 013.426 3.932 18.08 18.08 0 012.543 6.375 17.964 17.964 0 01.22 5.212 17.819 17.819 0 01-.726 3.502 18.156 18.156 0 01-3.491 6.314v.001a17.982 17.982 0 01-2.52 2.421 17.941 17.941 0 01-2.908 1.868 18.118 18.118 0 01-4.879 1.681 17.92 17.92 0 01-6.966-.052 17.994 17.994 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.207.657.892.892 0 00.319.611c.09.076.195.133.308.169a19.79 19.79 0 003.854.8 19.883 19.883 0 003.847.023 19.55 19.55 0 003.735-.711 19.907 19.907 0 005.164-2.352 19.933 19.933 0 003-2.365 19.685 19.685 0 004.463-6.456 19.937 19.937 0 001.204-3.817 19.622 19.622 0 00.058-7.669 19.804 19.804 0 00-1.849-5.365 19.979 19.979 0 00-2.054-3.198 19.581 19.581 0 00-4.214-3.971 19.657 19.657 0 00-1.685-1.05.898.898 0 00-.684-.078zM22.645 137.731a20.026 20.026 0 00-3.804.349 20.023 20.023 0 00-3.64 1.063 19.895 19.895 0 00-3.37 1.734 19.698 19.698 0 00-7.464 8.821 19.967 19.967 0 00-1.204 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.329 5.527 19.947 19.947 0 001.734 3.372 19.79 19.79 0 002.365 3 19.589 19.589 0 002.955 2.523c.54.375 1.101.726 1.685 1.05a.902.902 0 001.223-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.94-9.038 18.125 18.125 0 01-1.208-5.026 17.886 17.886 0 011.388-8.723 18.069 18.069 0 012.85-4.589 17.791 17.791 0 013.933-3.427 17.903 17.903 0 013.065-1.577 17.988 17.988 0 018.52-1.187 17.697 17.697 0 013.502.727.905.905 0 001.128-.589.898.898 0 00-.588-1.129 19.79 19.79 0 00-3.854-.8 19.883 19.883 0 00-1.931-.106z"
        fill="#FCFAF2"
      />
      <path
        d="M29.579 140.096l-5.619 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M25.539 135.546a.431.431 0 00-.454.313l-1.539 5.586a.429.429 0 00.523.529l5.618-1.463a.428.428 0 00.197-.716l-4.08-4.123a.432.432 0 00-.265-.126zm.177 1.256l3.034 3.067-4.179 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.47 174.904l5.618-1.463-1.539 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.157 173.018a.447.447 0 00-.178.008l-5.618 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.428.428 0 00.305-.301l1.539-5.586a.43.43 0 00-.345-.537zm-.68 1.025l-1.145 4.155-3.035-3.067 4.18-1.088zM22.524 171.196h2.683v-6.443h5.594v-2.473h-5.626v-2.473h5.626v-2.474h-4.155l6.563-11.575h-5.884l-4.801 9.684v15.754zm0 0H19.84v-6.443h-5.593v-2.473h5.626v-2.473h-5.626v-2.474H18.4l-6.563-11.575h5.885l4.8 9.684"
        fill="#FCFAF2"
      />
      <path
        d="M34.087 0h-23v23h23V0zM34.087 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpring0;
