import * as React from 'react';

function SvgSpringPlus6(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.707 22.681V25.937L3.607 30.264l20.102 5.085-20.102 5.085 20.102 5.085-20.102 5.085 20.103 5.085-20.103 5.087 20.102 5.085-20.102 5.085L23.71 76.03 3.606 81.115 23.708 86.2l-3 .76v6.822h5V90.526l17.1-4.326-20.106-5.085 20.105-5.085-20.105-5.085 20.105-5.085-20.102-5.085 20.102-5.085-20.103-5.087 20.103-5.085-20.102-5.085 20.102-5.085-20.103-5.085 3.003-.76v-6.822h-5z"
        fill={props.springcolor}
      />
      <path
        d="M23.207 140.297v6.092l-10.052 5.719 20.104 11.438-20.104 11.438 20.104 11.438-20.104 11.438 20.104 11.438-20.104 11.437 20.104 11.438-20.104 11.438 20.104 11.438-20.104 11.438 20.104 11.438-10.052 5.719v6.092"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.676 115.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.176 93a22.5 22.5 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M32.11 98.153a.9.9 0 00-.19 1.653 17.746 17.746 0 012.937 2.04 17.861 17.861 0 014.289 5.428 18.14 18.14 0 011.68 4.879 17.996 17.996 0 01.221 5.212 17.819 17.819 0 01-.727 3.502 18.156 18.156 0 01-2.405 4.908c-.34.49-.703.959-1.086 1.406v.001a17.96 17.96 0 01-3.932 3.426 17.904 17.904 0 01-3.065 1.578 18.128 18.128 0 01-5.025 1.208 18.011 18.011 0 01-3.495-.022 17.99 17.99 0 01-3.502-.726.905.905 0 00-.96.28.91.91 0 00-.207.657.893.893 0 00.32.611c.09.076.194.133.307.169a19.787 19.787 0 003.854.8 19.886 19.886 0 003.848.023 19.547 19.547 0 003.734-.711 19.908 19.908 0 005.164-2.352 19.971 19.971 0 003-2.365 19.684 19.684 0 004.464-6.456 19.964 19.964 0 001.203-3.817 19.684 19.684 0 00-.387-9.515 19.813 19.813 0 00-3.458-6.717 19.581 19.581 0 00-4.214-3.97 19.763 19.763 0 00-1.684-1.05.9.9 0 00-.685-.08zM23.297 95.731a19.707 19.707 0 00-5.65.794 19.89 19.89 0 00-6.717 3.458 19.594 19.594 0 00-2.775 2.666 19.694 19.694 0 00-3.136 5.049 19.97 19.97 0 00-1.203 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.328 5.527 19.95 19.95 0 001.734 3.372 19.787 19.787 0 002.366 3 19.59 19.59 0 002.954 2.523c.54.375 1.101.726 1.685 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.902 17.902 0 01-7.94-9.038 18.122 18.122 0 01-1.209-5.026 17.886 17.886 0 011.389-8.723 18.069 18.069 0 012.85-4.589 17.792 17.792 0 013.932-3.427 17.93 17.93 0 013.066-1.578 18.092 18.092 0 015.025-1.207 17.887 17.887 0 015.25.294c.585.12 1.168.272 1.747.454a.901.901 0 00.54-1.718 19.733 19.733 0 00-5.786-.906z"
        fill="#FCFAF2"
      />
      <path
        d="M30.231 98.097l-5.618 1.462 1.538-5.586 4.08 4.124z"
        fill="#F9AEA5"
      />
      <path
        d="M26.191 93.546a.43.43 0 00-.454.313l-1.538 5.587a.428.428 0 00.522.528l5.619-1.463a.43.43 0 00.197-.716l-4.08-4.123a.43.43 0 00-.266-.126zm.177 1.256l3.035 3.067-4.18 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.122 132.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.81 131.018a.447.447 0 00-.178.008l-5.619 1.463a.429.429 0 00-.197.716l4.08 4.123a.425.425 0 00.414.114.429.429 0 00.306-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.681 1.025l-1.144 4.155-3.035-3.067 4.179-1.088zM23.176 129.196h2.684v-6.443h5.593v-2.473h-5.625v-2.473h5.625v-2.474H27.3l6.563-11.574h-5.885l-4.8 9.683v15.754zm0 0h-2.683v-6.443h-5.594v-2.473h5.626v-2.473H14.9v-2.474h4.155l-6.563-11.574h5.884l4.801 9.683"
        fill="#FCFAF2"
      />
      <path d="M34.74 0h-23v23h23V0zM34.74 292h-23v23h23v-23z" fill="#5BAD92" />
    </svg>
  );
}

export default SvgSpringPlus6;
