import * as React from 'react';

function SvgSpringMinus3(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.728 25.134v5.245l-10.051 4.924L32.78 45.15 12.677 55l20.103 9.849-20.103 9.848 20.103 9.848-20.103 9.849 20.103 9.848-20.103 9.848 20.103 9.849-20.103 9.848 20.103 9.848-10.052 4.924v5.245M22.728 203.18v3.528l-10.051 3.313 20.103 6.625-20.103 6.625 20.103 6.625-20.103 6.624 20.103 6.625-20.103 6.625 20.103 6.625-20.103 6.625 20.103 6.625-20.103 6.625 20.103 6.625-10.052 3.313v3.528"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.198 178.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0122.698 156a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M31.63 161.154a.896.896 0 00-.617 1.112.9.9 0 00.428.54 17.662 17.662 0 012.937 2.04 17.84 17.84 0 013.427 3.932 18.08 18.08 0 012.543 6.375 17.964 17.964 0 01.22 5.212 17.819 17.819 0 01-.726 3.502 18.156 18.156 0 01-3.491 6.314v.001a17.982 17.982 0 01-2.52 2.421 17.941 17.941 0 01-2.909 1.868 18.118 18.118 0 01-4.878 1.681 17.92 17.92 0 01-6.967-.052 17.994 17.994 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.206.657.893.893 0 00.318.611c.091.076.196.133.308.169a19.79 19.79 0 003.855.8 19.883 19.883 0 003.847.023 19.55 19.55 0 003.735-.711 19.907 19.907 0 005.164-2.352 19.952 19.952 0 002.999-2.365 19.685 19.685 0 004.464-6.456 19.937 19.937 0 001.203-3.817 19.622 19.622 0 00.058-7.669 19.804 19.804 0 00-1.848-5.365 19.979 19.979 0 00-2.055-3.198A19.581 19.581 0 0034 162.282a19.657 19.657 0 00-1.685-1.05.898.898 0 00-.684-.078zM22.818 158.731a20.026 20.026 0 00-3.803.349 20.023 20.023 0 00-3.64 1.063 19.895 19.895 0 00-3.371 1.734 19.698 19.698 0 00-7.464 8.821 19.97 19.97 0 00-1.203 3.817 19.612 19.612 0 00-.058 7.669 19.83 19.83 0 006.568 11.338 19.467 19.467 0 003.234 2.246.902.902 0 001.223-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.94-9.038 18.122 18.122 0 01-1.208-5.026 17.89 17.89 0 01.294-5.251 17.94 17.94 0 011.094-3.472 18.069 18.069 0 012.85-4.589 17.791 17.791 0 013.933-3.427 17.903 17.903 0 013.065-1.577 17.988 17.988 0 018.52-1.187 17.697 17.697 0 013.502.727.905.905 0 001.128-.589.898.898 0 00-.588-1.129 19.79 19.79 0 00-3.854-.799 19.575 19.575 0 00-1.932-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M29.752 161.096l-5.618 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M25.712 156.546a.431.431 0 00-.454.313l-1.538 5.586a.43.43 0 00.523.529l5.618-1.463a.429.429 0 00.197-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.035 3.067-4.179 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.643 195.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.331 194.018a.447.447 0 00-.178.008l-5.619 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.415.114.429.429 0 00.305-.301l1.539-5.586a.43.43 0 00-.345-.537zm-.68 1.025l-1.145 4.155-3.035-3.067 4.18-1.088zM22.698 192.196h2.683v-6.443h5.593v-2.473H25.35v-2.473h5.625v-2.474H26.82l6.563-11.574H27.5l-4.801 9.683v15.754zm0 0h-2.684v-6.443h-5.593v-2.473h5.625v-2.473h-5.625v-2.474h4.154l-6.563-11.574h5.884l4.802 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.261 0h-23v23h23V0zM34.261 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringMinus3;
