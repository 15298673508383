import * as React from 'react';

function SvgSpringMinus5(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.51 24.927v5.838l-10.05 5.48 20.102 10.962L13.46 58.17l20.103 10.962L13.46 80.092l20.103 10.962-20.103 10.961 20.103 10.962-20.103 10.961L33.562 134.9 13.46 145.862l20.103 10.961-10.051 5.481v5.838M23.51 217.221v2.956l-10.05 2.776 20.102 5.55-20.103 5.55 20.103 5.55-20.103 5.55 20.103 5.551-20.103 5.55 20.103 5.55-20.103 5.55 20.103 5.55-20.103 5.551 20.103 5.55-10.051 2.775v2.956"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.98 192.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.48 170a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M32.413 175.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.645 17.645 0 012.938 2.04 17.861 17.861 0 014.289 5.428 18.139 18.139 0 011.68 4.879 17.964 17.964 0 01.221 5.212 17.819 17.819 0 01-.727 3.502 18.128 18.128 0 01-3.49 6.314v.001a17.96 17.96 0 01-3.933 3.426 17.903 17.903 0 01-3.065 1.578 18.128 18.128 0 01-5.025 1.208 18.011 18.011 0 01-3.495-.022 17.99 17.99 0 01-3.502-.726.905.905 0 00-.96.28.91.91 0 00-.207.657.893.893 0 00.319.611c.09.076.195.133.308.169a19.79 19.79 0 003.854.8 19.883 19.883 0 003.848.023 19.543 19.543 0 003.734-.711 19.895 19.895 0 005.164-2.352 19.952 19.952 0 003-2.365 19.685 19.685 0 004.464-6.456 19.964 19.964 0 001.203-3.817 19.653 19.653 0 00.058-7.669 19.773 19.773 0 00-1.063-3.639 19.959 19.959 0 00-1.734-3.372 19.78 19.78 0 00-3.771-4.327 19.457 19.457 0 00-3.234-2.246.898.898 0 00-.684-.078zM23.6 172.731a20.028 20.028 0 00-3.803.349 20.02 20.02 0 00-3.64 1.063 19.922 19.922 0 00-3.37 1.734 19.686 19.686 0 00-7.464 8.821 19.967 19.967 0 00-1.203 3.817 19.612 19.612 0 00-.058 7.669 19.834 19.834 0 006.568 11.338 19.493 19.493 0 003.233 2.246.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.902 17.902 0 01-7.941-9.038 18.122 18.122 0 01-1.208-5.026 17.89 17.89 0 01.294-5.251 17.943 17.943 0 011.095-3.472 18.069 18.069 0 012.85-4.589 17.794 17.794 0 013.932-3.427 17.904 17.904 0 013.066-1.577 17.988 17.988 0 018.52-1.187 17.701 17.701 0 013.502.727.905.905 0 001.129-.589.9.9 0 00-.59-1.129 19.787 19.787 0 00-3.854-.799 19.575 19.575 0 00-1.931-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M30.535 175.096l-5.619 1.463 1.539-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M26.495 170.546a.434.434 0 00-.454.313l-1.539 5.586a.429.429 0 00.523.529l5.619-1.463a.429.429 0 00.197-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.034 3.067-4.178 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.425 209.904l5.619-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M22.114 208.018a.447.447 0 00-.179.008l-5.618 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.429.429 0 00.306-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.681 1.025l-1.145 4.155-3.034-3.067 4.179-1.088zM23.48 206.196h2.684v-6.443h5.593v-2.473h-5.626v-2.473h5.626v-2.474h-4.155l6.564-11.574H28.28l-4.8 9.683v15.754zm0 0h-2.683v-6.443h-5.594v-2.473h5.626v-2.473h-5.626v-2.474h4.155l-6.564-11.574h5.885l4.801 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M35.044 0h-23v23h23V0zM35.044 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringMinus5;
