import * as React from 'react';

function SvgSpringMinus7(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.293 24.723v6.407l-10.051 6.016 20.103 12.03-20.103 12.032 20.103 12.03L13.242 85.27 33.345 97.3l-20.103 12.032 20.103 12.031-20.103 12.031 20.103 12.031-20.103 12.031 20.103 12.031-10.052 6.015v6.408"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M20.794 228.822v2.904l-17.197 3.877 20.103 4.531-20.103 4.533 20.103 4.532-20.103 4.531 20.103 4.534-20.103 4.531 20.101 4.531-20.101 4.534 20.101 4.533-20.101 4.531 20.103 4.533-2.906.655V292.236h5v-2.904l17.195-3.876-20.103-4.532 20.103-4.531-20.103-4.533 20.103-4.532-20.101-4.533 20.101-4.531-20.101-4.532 20.101-4.533-20.101-4.533 20.101-4.532-20.105-4.531 2.91-.656v-6.125h-5z"
        fill={props.springcolor}
      />
      <path
        d="M45.763 206.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.263 184a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M32.196 189.154a.897.897 0 00-.618 1.112.9.9 0 00.428.54 17.646 17.646 0 012.937 2.04 17.84 17.84 0 013.427 3.932 18.08 18.08 0 012.543 6.375 17.964 17.964 0 01.22 5.212 17.848 17.848 0 01-.726 3.502 18.156 18.156 0 01-3.491 6.314v.001a17.982 17.982 0 01-2.52 2.421 17.941 17.941 0 01-2.909 1.868 18.118 18.118 0 01-4.878 1.681 17.915 17.915 0 01-6.967-.052 17.994 17.994 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.206.657.893.893 0 00.318.611c.091.076.196.133.309.169a19.787 19.787 0 003.854.8 19.884 19.884 0 003.847.023 19.552 19.552 0 003.735-.711 19.922 19.922 0 005.164-2.352 19.933 19.933 0 002.999-2.365 19.685 19.685 0 004.464-6.456 19.963 19.963 0 001.203-3.817 19.622 19.622 0 00.058-7.669 19.804 19.804 0 00-1.848-5.365 19.95 19.95 0 00-2.055-3.198 19.581 19.581 0 00-4.213-3.971 19.674 19.674 0 00-1.685-1.05.897.897 0 00-.684-.078zM23.383 186.731a20.026 20.026 0 00-3.803.349 20.023 20.023 0 00-3.64 1.063 19.895 19.895 0 00-3.371 1.734 19.7 19.7 0 00-7.464 8.821 19.967 19.967 0 00-1.203 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.329 5.527 19.947 19.947 0 001.734 3.372 19.79 19.79 0 002.365 3 19.579 19.579 0 002.955 2.523c.539.375 1.1.726 1.684 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.94-9.038 18.125 18.125 0 01-1.208-5.026 17.886 17.886 0 011.388-8.723 18.069 18.069 0 012.85-4.589 17.793 17.793 0 013.933-3.427 17.903 17.903 0 013.065-1.577 17.988 17.988 0 018.52-1.187 17.697 17.697 0 013.502.727.905.905 0 001.128-.589.898.898 0 00-.588-1.129 19.79 19.79 0 00-3.854-.799 19.574 19.574 0 00-1.932-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M30.317 189.096l-5.618 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M26.277 184.546a.431.431 0 00-.454.313l-1.538 5.586a.43.43 0 00.523.529l5.618-1.463a.429.429 0 00.197-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.035 3.067-4.179 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M16.208 223.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.896 222.018a.447.447 0 00-.178.008l-5.619 1.463a.43.43 0 00-.197.716l4.08 4.123a.426.426 0 00.415.114.428.428 0 00.305-.301l1.539-5.586a.43.43 0 00-.345-.537zm-.68 1.025l-1.145 4.155-3.035-3.067 4.18-1.088zM23.263 220.196h2.683v-6.443h5.593v-2.473h-5.625v-2.473h5.625v-2.474h-4.154l6.563-11.574h-5.884l-4.801 9.683v15.754zm0 0h-2.684v-6.443h-5.593v-2.473h5.625v-2.473h-5.625v-2.474h4.154l-6.563-11.574h5.884l4.802 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.826 0h-23v23h23V0zM34.826 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringMinus7;
