import * as React from 'react';

function SvgSpringPlus2(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.772 24.956v3.84L12.72 32.4l20.104 7.209-20.104 7.209 20.104 7.209-20.104 7.208 20.104 7.21-20.104 7.208 20.104 7.209L12.72 90.07l20.104 7.209-20.104 7.208 20.104 7.209-10.052 3.605v3.839M22.772 168.26v4.952L12.72 177.86l20.104 9.298-20.104 9.298 20.104 9.298-20.104 9.297 20.104 9.298-20.104 9.298 20.104 9.297-20.104 9.298 20.104 9.298-20.104 9.297 20.104 9.298-10.052 4.649v4.952"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.242 143.5a22.502 22.502 0 01-22.5 22.5 22.502 22.502 0 01-22.5-22.5 22.502 22.502 0 0138.41-15.91 22.502 22.502 0 016.59 15.91z"
        fill="#5BAD92"
      />
      <path
        d="M31.674 126.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.646 17.646 0 012.938 2.04 17.86 17.86 0 014.29 5.428 18.14 18.14 0 011.68 4.879 17.964 17.964 0 01.22 5.212 17.848 17.848 0 01-.727 3.502 18.183 18.183 0 01-2.405 4.908c-.34.49-.703.959-1.085 1.406v.001a17.983 17.983 0 01-2.521 2.421 17.904 17.904 0 01-2.908 1.868 18.116 18.116 0 01-4.878 1.681 17.915 17.915 0 01-6.967-.052 17.989 17.989 0 01-1.746-.454.904.904 0 00-.96.28.912.912 0 00-.206.657.895.895 0 00.318.611c.09.076.195.133.308.169a19.787 19.787 0 003.854.8 19.886 19.886 0 003.848.023 19.547 19.547 0 003.734-.711 19.922 19.922 0 005.164-2.352 19.954 19.954 0 003-2.365 19.707 19.707 0 004.464-6.456 19.963 19.963 0 001.203-3.817 19.653 19.653 0 00.058-7.669 19.836 19.836 0 00-2.797-7.011 19.754 19.754 0 00-5.32-5.523 19.674 19.674 0 00-1.684-1.05.897.897 0 00-.685-.078zM22.862 123.731a20.026 20.026 0 00-3.804.349 20.013 20.013 0 00-3.639 1.063 19.908 19.908 0 00-3.371 1.734 19.704 19.704 0 00-7.464 8.821 19.97 19.97 0 00-1.203 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.328 5.527 19.947 19.947 0 001.734 3.372 19.787 19.787 0 002.366 3 19.587 19.587 0 002.954 2.523c.54.375 1.102.726 1.685 1.05a.903.903 0 001.224-.35.899.899 0 00-.35-1.223 17.9 17.9 0 01-7.94-9.038 18.122 18.122 0 01-1.209-5.026 17.89 17.89 0 01.294-5.251 17.94 17.94 0 011.095-3.472 18.069 18.069 0 012.85-4.589 17.777 17.777 0 013.932-3.427 17.904 17.904 0 013.066-1.577 17.98 17.98 0 018.52-1.187 17.697 17.697 0 013.502.727.906.906 0 001.128-.589.898.898 0 00-.588-1.129 19.787 19.787 0 00-3.854-.799 19.577 19.577 0 00-1.932-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M29.796 126.096l-5.618 1.463 1.538-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M25.756 121.546a.432.432 0 00-.454.313l-1.538 5.587a.425.425 0 00.108.414.426.426 0 00.414.114l5.619-1.463a.43.43 0 00.197-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.035 3.067-4.179 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.687 160.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.375 159.018a.447.447 0 00-.178.008l-5.619 1.463a.429.429 0 00-.197.716l4.08 4.123a.426.426 0 00.414.114.43.43 0 00.306-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.681 1.025l-1.144 4.155-3.035-3.067 4.179-1.088zM22.741 157.196h2.684v-6.443h5.593v-2.473h-5.626v-2.473h5.626v-2.474h-4.154l6.563-11.574h-5.884l-4.802 9.683v15.754zm0 0h-2.683v-6.443h-5.594v-2.473h5.626v-2.473h-5.626v-2.474h4.155l-6.563-11.574h5.884l4.801 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.305 0h-23v23h23V0zM34.305 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringPlus2;
