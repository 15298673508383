import * as React from 'react';

function SvgSpringMinus1(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.946 25.34v4.668l-10.052 4.381 20.103 8.763-20.103 8.763 20.103 8.763-20.103 8.763 20.103 8.763-20.103 8.763 20.103 8.763-20.103 8.763 20.103 8.763-20.103 8.763 20.103 8.762-10.051 4.382v4.667M22.946 189.352v4.092l-10.052 3.842 20.103 7.683-20.103 7.684 20.103 7.683-20.103 7.683 20.103 7.683-20.103 7.684 20.103 7.683-20.103 7.683 20.103 7.684-20.103 7.683 20.103 7.683-10.051 3.842v4.092"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.415 164.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0122.915 142a22.502 22.502 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M31.848 147.154a.896.896 0 00-.618 1.112.9.9 0 00.428.54 17.645 17.645 0 012.938 2.04 17.861 17.861 0 014.289 5.428 18.14 18.14 0 011.68 4.879 17.996 17.996 0 01.221 5.212 17.819 17.819 0 01-.727 3.502 18.156 18.156 0 01-2.405 4.908c-.34.49-.703.959-1.086 1.406v.001a17.96 17.96 0 01-3.932 3.426 17.904 17.904 0 01-3.065 1.578 18.128 18.128 0 01-5.025 1.208 18.011 18.011 0 01-3.495-.022 17.99 17.99 0 01-3.502-.726.905.905 0 00-.96.28.91.91 0 00-.207.657.893.893 0 00.319.611c.09.076.195.133.308.169a19.787 19.787 0 003.854.8 19.886 19.886 0 003.848.023 19.547 19.547 0 003.734-.711 19.908 19.908 0 005.164-2.352 19.971 19.971 0 003-2.365 19.684 19.684 0 004.464-6.456 19.964 19.964 0 001.203-3.817 19.684 19.684 0 00-.387-9.515 19.813 19.813 0 00-3.458-6.717 19.581 19.581 0 00-4.214-3.971 19.688 19.688 0 00-1.685-1.05.898.898 0 00-.684-.078zM23.036 144.731a20.028 20.028 0 00-3.804.349 20.02 20.02 0 00-3.64 1.063 19.922 19.922 0 00-3.37 1.734 19.695 19.695 0 00-7.464 8.821 19.97 19.97 0 00-1.203 3.817 19.609 19.609 0 00-.324 5.781 19.743 19.743 0 001.328 5.527 19.95 19.95 0 001.734 3.372 19.787 19.787 0 002.365 3 19.59 19.59 0 002.955 2.523c.54.375 1.101.726 1.685 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.902 17.902 0 01-7.941-9.038 18.122 18.122 0 01-1.208-5.026 17.89 17.89 0 01.294-5.251 17.938 17.938 0 011.095-3.472 18.069 18.069 0 012.85-4.589 17.792 17.792 0 013.932-3.427 17.904 17.904 0 013.066-1.577 18 18 0 018.52-1.187 17.701 17.701 0 013.502.727.905.905 0 001.129-.589.9.9 0 00-.59-1.129 19.787 19.787 0 00-3.854-.799 19.575 19.575 0 00-1.931-.107z"
        fill="#FCFAF2"
      />
      <path
        d="M29.97 147.096l-5.619 1.463 1.539-5.586 4.08 4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M25.93 142.546a.434.434 0 00-.454.313l-1.539 5.586a.429.429 0 00.523.529l5.618-1.463a.429.429 0 00.198-.716l-4.08-4.123a.432.432 0 00-.266-.126zm.177 1.256l3.035 3.067-4.18 1.088 1.145-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.86 181.904l5.619-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.549 180.018a.447.447 0 00-.179.008l-5.618 1.463a.429.429 0 00-.197.716l4.08 4.123a.425.425 0 00.414.114.429.429 0 00.306-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.681 1.025l-1.145 4.155-3.034-3.067 4.179-1.088zM22.915 178.196H25.6v-6.443h5.593v-2.473h-5.626v-2.473h5.626v-2.474h-4.155l6.564-11.574h-5.885l-4.8 9.683v15.754zm0 0h-2.683v-6.443h-5.594v-2.473h5.626v-2.473h-5.626v-2.474h4.155l-6.563-11.574h5.884l4.801 9.683"
        fill="#FCFAF2"
      />
      <path
        d="M34.479 0h-23v23h23V0zM34.479 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringMinus1;
