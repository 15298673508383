import * as React from 'react';

function SvgSpringPlus9(props) {
  return (
    <svg
      width={46}
      height={315}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.534 22.611v2.28L3.327 27.933l20.103 3.558-20.103 3.556 20.103 3.557-20.103 3.558 20.105 3.557-20.105 3.556 20.103 3.558-20.103 3.557 20.103 3.556-20.103 3.558 20.101 3.557-2.894.512V72.384h5v-2.277l17.205-3.045-20.102-3.557 20.102-3.558-20.104-3.556 20.104-3.557-20.102-3.558L42.74 45.72l-20.102-3.557 20.102-3.558-20.104-3.556 20.104-3.557-20.102-3.557 2.897-.513V22.612h-5z"
        fill={props.springcolor}
      />
      <path
        d="M23.033 118.704v6.972l-10.051 6.545 20.103 13.091-20.103 13.09 20.103 13.091-20.103 13.091 20.103 13.09-20.103 13.091 20.103 13.091-20.103 13.091 20.103 13.09-20.103 13.091 20.103 13.091-10.052 6.545v6.972"
        stroke={props.springcolor}
        strokeWidth={5}
        strokeLinecap="square"
      />
      <path
        d="M45.503 94.5a22.501 22.501 0 01-38.41 15.91A22.502 22.502 0 0123.003 72a22.5 22.5 0 0122.5 22.5z"
        fill="#5BAD92"
      />
      <path
        d="M31.936 77.153a.9.9 0 00-.19 1.653 17.943 17.943 0 014.215 3.246 17.91 17.91 0 013.011 4.222 18.114 18.114 0 011.681 4.88 17.984 17.984 0 01.22 5.21 17.814 17.814 0 01-.726 3.503 18.095 18.095 0 01-3.491 6.314v.001a17.96 17.96 0 01-3.932 3.426 17.888 17.888 0 01-3.066 1.578 18.128 18.128 0 01-5.025 1.208 18.011 18.011 0 01-3.495-.022 17.99 17.99 0 01-3.502-.726.905.905 0 00-.96.28.91.91 0 00-.206.657.893.893 0 00.318.611c.091.076.196.133.308.169a19.782 19.782 0 005.786.906 19.543 19.543 0 005.65-.794 19.908 19.908 0 005.164-2.352 19.952 19.952 0 003-2.365 19.685 19.685 0 004.464-6.456 19.964 19.964 0 001.203-3.817 19.635 19.635 0 00.058-7.669 19.826 19.826 0 00-2.796-7.01 19.775 19.775 0 00-3.772-4.328 19.618 19.618 0 00-3.233-2.246.9.9 0 00-.684-.079zM23.123 74.731a19.707 19.707 0 00-5.65.794 19.89 19.89 0 00-6.717 3.458 19.681 19.681 0 00-5.91 7.715 19.985 19.985 0 00-1.204 3.817 19.628 19.628 0 00-.058 7.669 19.851 19.851 0 005.162 10.011 19.59 19.59 0 002.955 2.523c.539.375 1.1.726 1.684 1.05a.902.902 0 001.224-.35.899.899 0 00-.35-1.223 17.902 17.902 0 01-7.94-9.038 18.143 18.143 0 01-1.208-5.026 17.883 17.883 0 011.388-8.722 18.2 18.2 0 011.765-3.184 17.824 17.824 0 015.018-4.833 18.012 18.012 0 014.695-2.14 18.034 18.034 0 015.136-.72 17.817 17.817 0 015.256.823.901.901 0 00.54-1.718 19.733 19.733 0 00-5.786-.906z"
        fill="#FCFAF2"
      />
      <path
        d="M30.057 77.097l-5.618 1.462 1.538-5.586 4.08 4.124z"
        fill="#F9AEA5"
      />
      <path
        d="M26.017 72.546a.43.43 0 00-.454.313l-1.538 5.587a.427.427 0 00.3.526c.073.02.15.02.223.002l5.618-1.463a.43.43 0 00.197-.716l-4.08-4.123a.43.43 0 00-.266-.126zm.177 1.256l3.035 3.067-4.179 1.088 1.144-4.155z"
        fill="#FCFAF2"
      />
      <path
        d="M15.948 111.904l5.618-1.463-1.538 5.586-4.08-4.123z"
        fill="#F9AEA5"
      />
      <path
        d="M21.636 110.018a.447.447 0 00-.178.008l-5.619 1.463a.429.429 0 00-.197.716l4.08 4.123a.425.425 0 00.415.114.429.429 0 00.305-.301l1.538-5.586a.428.428 0 00-.344-.537zm-.68 1.025l-1.145 4.155-3.035-3.067 4.18-1.088zM23.003 108.196h2.683v-6.443h5.593V99.28h-5.625v-2.473h5.625v-2.474h-4.154l6.563-11.575h-5.884l-4.801 9.684v15.754zm0 0h-2.684v-6.443h-5.593V99.28h5.625v-2.473h-5.625v-2.474h4.154l-6.563-11.575h5.884l4.802 9.684"
        fill="#FCFAF2"
      />
      <path
        d="M34.566 0h-23v23h23V0zM34.566 292h-23v23h23v-23z"
        fill="#5BAD92"
      />
    </svg>
  );
}

export default SvgSpringPlus9;
